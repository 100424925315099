import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import AppPrivacyPolicy from './components/AppPrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Main from './Main';
import i18n from './utils/i18n';
import Register from './Register.js';
import DeleteConfirmation from './DeleteConfirmation.js';
import DeleteInstructions from './DeleteInstructions.js';
import Confirm from './Confirm.js';
import Home from './components/Home';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function App() {
  const [language, setLanguage] = useState(localStorage.getItem('language_0'));
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language_0', language);
    document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
    setLanguage(language);
  };

  const home = <>
  <GoogleReCaptchaProvider
  reCaptchaKey='6LchFkEnAAAAAC0rBJyZ0oBcy74NnHpfdcI9Dxns'
  language={language}
><Home changeLanguage={changeLanguage} /></GoogleReCaptchaProvider>
</>;
  const register = <>
  <GoogleReCaptchaProvider
  reCaptchaKey='6LchFkEnAAAAAC0rBJyZ0oBcy74NnHpfdcI9Dxns'
  language={language}
><Register changeLanguage={changeLanguage} /></GoogleReCaptchaProvider>
</>;
  const NotReady = () => <>
  <GoogleReCaptchaProvider
  reCaptchaKey='6LchFkEnAAAAAC0rBJyZ0oBcy74NnHpfdcI9Dxns'
  language={language}
><div>Under construction.</div></GoogleReCaptchaProvider>
</>;
  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy"  element={<PrivacyPolicy />} />
        <Route path="/app-privacy-policy"  element={<AppPrivacyPolicy />} />
        <Route path="/terms-and-conditions"  element={<TermsConditions />} />
        <Route path="/menu/*" element={<Main />} />
        <Route path="/register" element={register} />
        <Route path="/forgot-password" element={<NotReady />} />
        <Route path="/deleteRestaurant" element={<DeleteConfirmation />} />
        <Route path="/deleteInstructions" element={<DeleteInstructions changeLanguage={changeLanguage} />} />
        <Route path="/confirm/*" element={<Confirm />} />
        <Route path="/" element={home} />
      </Routes>
    </Router>
  );
}
